<template>
  <v-snackbar v-model="notification.display"
              :color="notification.color"
              :timeout="notification.timeout"
              multi-line
              bottom>
    {{ notification.text }}
    <v-btn v-if="notification.persist"
           dark
           text
           @click="notification.display = false">
      Close
    </v-btn>
  </v-snackbar>
</template>

<script>
import { EventBus } from '@/utils/event-bus';

export default {
  data() {
    return {
      notification: {
        display: false,
        timeout: undefined,
        persist: undefined,
        color: '',
        text: '',
        icon: undefined,
      },
    };
  },

  mounted() {
    EventBus.$on('notify', (type, message, icon, persist, timeout) => {
      let timeoutCount = timeout || 3000;
      if (persist) {
        timeoutCount = 0;
      }

      this.notification.color = type;
      this.notification.text = message;
      this.notification.display = true;
      this.notification.timeout = timeoutCount;
      this.notification.persist = persist;
      this.notification.icon = icon;
    });
  },
};
</script>
